import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import Users from './components/pages/Users';
import User from './components/pages/User';
import UsersGroups from './components/pages/UsersGroups'
import UsersGroup from './components/pages/UsersGroup'
import ContentCategory from './components/pages/ContentCategory'
import ContentCategories from './components/pages/ContentCategories'
import ContentTag from './components/pages/ContentTag'
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store'
import ContentTags from './components/pages/ContentTags';
import Video from './components/pages/Video';
import Videos from './components/pages/Videos';
import Album from './components/pages/Album';
import Albums from './components/pages/Albums';
import Channels from './components/pages/Channels';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dashboard from './components/pages/Dashboard';
import Models from './components/pages/Models';
import Model from './components/pages/Model';
import Pages from './components/pages/Pages';
import Page from './components/pages/Page';
import Settings from './components/pages/Settings';

const THEME = createTheme({
  typography: {
    "fontFamily": `"Outfit", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Dashboard />,  
      },
      {
        path: "/models/:tab/page/:page/detail/:subTab",
        element: <Models />
      },
      {
        path: "/models/:tab/page/:page/detail/:subTab/page/:subpage",
        element: <Models />
      },
      {
        path: "/models",
        element: <Models />,  
      },
      {
        path: "/model/:id",
        element: <Model />
      },
      {
        path: "/models/:tab",
        element: <Models />
      },
      {
        path: "/models/:tab/page/:page",
        element: <Models />,  
      },
      {
        path: "/pages",
        element: <Pages />,  
      },
      {
        path: "/page/:id",
        element: <Page />
      },
      {
        path: "/pages/:tab",
        element: <Pages />
      },
      {
        path: "/pages/:tab/page/:page",
        element: <Pages />,  
      },
      {
        path: "/users",
        element: <Users />,  
      },
      {
        path: "/user/:id",
        element: <User />
      },
      {
        path: "/users/:tab/page/:page/detail/:subTab",
        element: <Users />
      },
      {
        path: "/users/:tab/page/:page/detail/:subTab/page/:subpage",
        element: <Users />
      },
      {
        path: "/users/:tab",
        element: <Users />
      },
      {
        path: "/users/:tab/page/:page",
        element: <Users />,  
      },
      {
        path: "/users-groups",
        element: <UsersGroups />
      },
      {
        path: "/users-groups/page/:page",
        element: <UsersGroups />,  
      },
      {
        path: "/users-group/:id",
        element: <UsersGroup />
      },
      {
        path: "/channels/:tab",
        element: <Channels />
      },
      {
        path: "/channels/:tab/page/:page",
        element: <Channels />,  
      },
      {
        path: "/channels",
        element: <Channels />
      },
      {
        path: "/content-categories",
        element: <ContentCategories />
      },
      {
        path: "/content-category/:id",
        element: <ContentCategory />
      },
      {
        path: "/content-categories/page/:page",
        element: <ContentCategories />,  
      },
      {
        path: "/content-tag/:id",
        element: <ContentTag />
      },
      {
        path: "/content-tags",
        element: <ContentTags />
      },
      {
        path: "/content-tags/page/:page",
        element: <ContentTags />,  
      },
      {
        path: "/video/:id",
        element: <Video />
      },
      {
        path: "/videos",
        element: <Videos />
      },
      {
        path: "/videos/:tab",
        element: <Videos />
      },
      {
        path: "/videos/:tab/history/page/:subpage",
        element: <Videos />
      },
      {
        path: "/videos/:tab/page/:page",
        element: <Videos />,  
      },
      {
        path: "/videos/:tab/page/:page/detail/:subTab/page/:subpage",
        element: <Videos />
      },
      {
        path: "/videos/:tab/page/:page/detail/:subTab/page/:subpage",
        element: <Videos />
      },
      {
        path: "/videos/:tab/page/:page/detail/:subTab",
        element: <Videos />
      },
      {
        path: "/albums",
        element: <Albums />,  
      },
      {
        path: "/album/:id",
        element: <Album />
      },
      {
        path: "/albums/:tab",
        element: <Albums />
      },
      {
        path: "/albums/:tab/page/:page",
        element: <Albums />,  
      },
      {
        path: "/albums/:tab/page/:page/detail/:subTab/page/:subpage",
        element: <Albums />
      },
      {
        path: "/albums/:tab/page/:page/detail/:subTab",
        element: <Albums />
      },
      {
        path: "/settings",
        element: <Settings />
      },
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
reportWebVitals();
