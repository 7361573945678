import './App.css';
import { Outlet } from "react-router-dom";
import Navigation from './components/navigation/Navigation';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import TubeButton from './components/form/TubeButton';
import { appUrl, get, post } from './utils/fetch';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { edit } from './slices/settingsSlice';

function App() {
    const dispatch = useDispatch();

    const [ pass, setPass ] = useState("");
    const [ passErr, setPassErr ] = useState(false);
    const [ isLoggedIn, setIsLoggedIn ] = useState(Cookies.get('isLoggedIn'));

    const login = () => {
        post(`${appUrl}/api/login`, {
            password: pass
        }, (result) => {
            if(result.data === true){
                Cookies.set('isLoggedIn', 'true');
                setIsLoggedIn('true');
            }
            else {
                setPassErr(true);
            }
        })
    }

    useEffect(() => {
        if(isLoggedIn === "true"){
            get(
                `${appUrl}/api/settings`,
                (data) => {
                    dispatch(edit({...data.data}));
                }
            )
        }
    }, [isLoggedIn]);

    return (
        <>
        {isLoggedIn !== "true" ? (
            <div className='login-window'>
                <TextField 
                    id={"password"}
                    label={"Password"}
                    variant="outlined"
                    type={"password"}
                    className="text-input"
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                />
                <TubeButton
                    title="Log in"
                    onClick={() => login()}
                />
                {passErr ? (
                    <div className='incorrect-password'>
                        Password is incorrect!
                    </div>
                ) : null}
            </div>
        ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en"}>
                <div 
                    className="App"
                    style={{
                        display: "inline-flex",
                        width: "100%",
                        fontFamily: "arial !important"
                    }}
                >
                    <Navigation />  
                    <div className="content" style={{
                        padding: "20px 30px",
                        width: "100%",
                        marginLeft: 232,
                        position: "relative",
                        paddingTop: 90
                    }}>
                        <Outlet />
                    </div>
                </div>
            </LocalizationProvider>
        )}
        </>
    );
}

export default App;
