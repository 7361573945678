import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    adultWarning: false,
    cookiesNotification: false,
    allowSuperAdmin: true,
    cookiesNotificationContent: ""
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = settingsSlice.actions

export default settingsSlice.reducer