import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import PropTypes, { array } from 'prop-types';
import Dialog from '../dialog/Dialog';
import { capitalizeEveryWord, get, post } from '../../utils/fetch';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DataTablePagination from './DataTablePagination';
import { CircularProgress } from '@mui/material';
import { appUrl } from "../../utils/fetch";
import { Avatar } from '@mui/material';
import TubeButton from '../form/TubeButton';
import TubeChip from '../form/TubeChip';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import TubeDialog from '../form/TubeDialog';
import CheckOutsideRefWrapper from '../wrappers/CheckOutsideRefWrapper';
import './DataTable.css';
import TubeCheckbox from '../form/TubeCheckbox';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/system';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { edit as meta } from '../../slices/metaSlice';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AddAlertOutlined, CrisisAlertOutlined, History, WarningAmber } from '@mui/icons-material';

const getData = (data, header, setFetchData, setFetchDataAll, setIsLoading, dispatch) => {
    if(data.data){
        let headerArray = Object.keys(header).map(key => {return key});
        let fetchedData = data.data.data.map(row => {
            let rowData = {};
            Object.keys(row).forEach(key => {
                if(headerArray.includes(key)){
                    rowData = { ...rowData, [key]: row[key] };
                }
            });
            return rowData;
        })
        setFetchData({ ...data.data, data: fetchedData });
        setFetchDataAll({ ...data.data, data: data.data.data });
        setIsLoading(false);
        if(data.data.meta){
            dispatch(meta(data.data.meta));
        };
    }
}

export default function DataTable(props) {

    const { deleteUrl, url, dataUrl, header, editComponent, bulkEditComponent,selectedStateName, dispatchFunc, largeView, filterStateName, actions, editMaxWidth, editComponentIsLoading, autoRefresh, closeFunc, editOpenFunc, disableBulk, userId, modelId, disableSelect, disableHeader, protectedFirst, subpageUrl, extraUtils, className } = props;

    const { page, subpage, subTab } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [ sort, setSort ] = React.useState({
        column: "id",
        order: "asc"
    })
    const [ showPerPage, setShowPerPage ] = React.useState(10);
    const [ prevShowPerPage, setPrevShowPerPage ] = React.useState(10);
    const [ idDelete, setIdDelete ] = React.useState([]);
    const [ idEdit, setIdEdit ] = React.useState(0);
    const [ openDelete, setOpenDelete ] = React.useState(false);
    const [ openEdit, setOpenEdit ] = React.useState(false);
    const [ isLoadingDelete, setIsLoadingDelete ] = React.useState(false);
    const [ fetchData, setFetchData ] = React.useState({
        data: [],
        last_page: 1,
        meta: []
    });
    const [ fetchDataAll, setFetchDataAll ] = React.useState({
        data: [],
        last_page: 1,
        meta: []
    });
    const [ view, setView ] = React.useState(largeView ? "large" : "compressed");
    const [ isOpenBulkActions, setIsOpenBulkActions ] = React.useState(false);
    const [ isOpenFilter, setIsOpenFilter ] = React.useState(false);
    const [ isOpenBulkEdit, setIsOpenBulkEdit ] = React.useState(false);
    const [ columnFilter, setColumnFilter ] = React.useState({});
    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ filteredDataUrl, setFilteredDataUrl ] = React.useState(dataUrl);
    const [ prevFilteredDataUrl, setPrevFilteredDataUrl ] = React.useState("");
    const [ filterCache, setFilterCache ] = React.useState({});
    
    let selected = useSelector((state) => state[selectedStateName]?.selected);
    const filter = useSelector((state) => state[filterStateName]);
    const selectedNames = useSelector((state) => state[selectedStateName]?.selectedNames);
    const isInitialMount = React.useRef(true);
    const dispatch = useDispatch();
    if(!selected){
        selected = [];
    }

    const getDataWrapper = () => {
        get(filteredDataUrl, (data) => getData(data, header, setFetchData, setFetchDataAll, setIsLoading, dispatch), setIsLoading(true));
    }

    React.useEffect(() => {
        let headerFilter = {};
        Object.keys(header).forEach((key) => {
            headerFilter = { ...headerFilter, [key]: true }
        });
        setColumnFilter(headerFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataUrl, header]);

    React.useEffect(() => {
        if(autoRefresh){
            const interval = setInterval(() => {
                getDataWrapper();
            }, 10000);
            return () => {
                clearInterval(interval);
            };
        }
    }, []);

    React.useEffect(() => {
        if(filter){
            handleFilter();
        }
    }, [filter, page, showPerPage, sort, subpage]);

    React.useEffect(() => {
        if(prevShowPerPage !== showPerPage){
            console.log(fetchData.last_page);
            navigate(`${url}/page/1`);   
            setPrevShowPerPage(showPerPage);
        }
    }, [fetchData.last_page]);

    React.useEffect(() => {
        getDataWrapper();
        let filterString = filteredDataUrl.split("?");
        // Create a new URLSearchParams object
        let filterData = new URLSearchParams(filterString[1]);

        // Convert the parameters to an object
        const paramsObject = {};
        let redirectToFirst = false;
        filterData.forEach((value, key) => {
            paramsObject[key] = value;
            if(key !== "page" && paramsObject[key] !== filterCache[key] && Object.keys(filterCache).length !== 0){
                redirectToFirst = true;
            }
        });
        setFilterCache(paramsObject);
        if(redirectToFirst){
            const updatedFrontendUrl = location.pathname.replace(/(\/page\/)\d+/, '$11');
            navigate(updatedFrontendUrl.replace(appUrl, ""));
        }
    }, [filteredDataUrl]);

    const handleSort = (key) => {
        console.log(key);
        setSort({
            column: key,
            order: key === sort.column && sort.order === "asc" ? "desc" : "asc"
        })
    }

    const handleFilter = (firstPage = false) => {
        let filterArray = [`page=${firstPage ? "1" : subpageUrl ? subpage : page}`, `limit=${showPerPage}`, `column=${sort.column}`, `order=${sort.order}`];
        if(userId){
            filterArray = [ ...filterArray, `user_id=${userId}` ];  
        }
        if(modelId){
            filterArray = [ ...filterArray, `model_id=${modelId}` ];  
        }
        Object.keys(filter).forEach((item) => {
            let value;
            if(typeof filter[item] !== "string"){
                value = JSON.stringify(filter[item]);
            }
            else value = filter[item];
            filterArray = [...filterArray, `${item}=${value}`]
        })
        let filterString = `?${filterArray.join("&")}`;
        if(prevFilteredDataUrl !== filterString){
            setPrevFilteredDataUrl(filterString);
            setFilteredDataUrl(`${dataUrl}${filterString}`);
        }
    }

    const handleDelete = (id) => {
        setOpenDelete(true);
        setIdDelete(id);
    }

    const handleEdit = (id) => {
        setOpenEdit(true);
        setIdEdit(id);
    }
    
    const handleDeleteDone = () => {
        setOpenDelete(false);
        setIdDelete([]);
        setOpenEdit(false);
        setTimeout(() => {
            setIsLoadingDelete(false);
        }, 200);
        getDataWrapper();
        dispatch(dispatchFunc({selected: [], selectedNames: []}));
    }

    const handleSelect = (id, name) => {
        let tempSelected = [...selected];
        let tempSelectedNames = [...selectedNames];
        if(selected && selected.includes(id, name)){
            const index = tempSelected.indexOf(id);
            tempSelected.splice(index, 1); 
            tempSelectedNames.splice(index, 1);
        }
        else {
            tempSelected.push(id);
            tempSelectedNames.push(name);
        }
        dispatch(dispatchFunc({selected: [...tempSelected], selectedNames: [...tempSelectedNames]}));
    }

    const handleSelectAll = () => {
        if(selected && selected.length){
            dispatch(dispatchFunc({selected: [], selectedNames: []}));
        }
        else {
            let allIds = fetchData.data.map(row => {
                return row.id;
            });
            let allNames = fetchData.data.map(row => {
                return row.title ? row.title : row.name;
            });
            dispatch(dispatchFunc({selected: [...allIds], selectedNames: [...allNames]}));
        }
    }

    const getIcon = (icon) => {
        switch(icon){
            case "history":
                return (
                    <History sx={{ fontSize: 20, color: "#004159" }}/>
                );
            case "alert":
                return (
                    <WarningAmber sx={{ fontSize: 20, color: "#004159" }}/>
                );
            default:
                break;
        }
    }

    return (
        <TableContainer className={className}>
            <div style={{
                padding: "10px 0",
                position: "relative"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}>    
                    {!disableBulk ? (
                        <CheckOutsideRefWrapper
                            children={(
                                <>
                                    <TubeButton
                                        title="Bulk Action"
                                        onClick={() => setIsOpenBulkActions(!isOpenBulkActions)}
                                    />
                                    <TubeDialog
                                        content={(
                                            <>
                                                <div style={{marginBottom: 10}}>Select bulk action</div>
                                                {bulkEditComponent ? (
                                                    <TubeButton
                                                        title="Edit"
                                                        onClick={() => setIsOpenBulkEdit(true)}
                                                    />
                                                ) : null}
                                                <TubeButton
                                                    title="Delete"
                                                    onClick={() => handleDelete(selected)}
                                                />
                                            </>
                                        )}
                                        isOpen={isOpenBulkActions}
                                        width={"auto"}
                                        sx={{
                                            left: 0
                                        }}
                                    />
                                </>
                            )}
                            func={() => setIsOpenBulkActions(false)}
                        />
                    ) : null}
                    {actions ? (
                        <div style={{
                            marginBottom: 0,
                            height: 48
                        }}>
                            {actions}
                        </div>
                    ) : null}
                    <div style={{
                        float: "right",
                        fontWeight: 500,
                        display: "flex",
                        flex: 1,
                        justifyContent: "right",
                        alignItems: "center"
                    }}>
                        {largeView ? (
                            <>
                                View
                                <TubeButton
                                    sx={{
                                        marginLeft: 10
                                    }}
                                    title="Large"
                                    active={view === "large"}
                                    onClick={() => setView("large")}
                                />      
                                <TubeButton
                                    title="Compressed"
                                    active={view === "compressed"}
                                    onClick={() => setView("compressed")}
                                />
                            </>
                        ) : null}
                        <CheckOutsideRefWrapper
                            children={(
                                <>
                                    <TubeButton
                                        sx={{
                                            marginLeft: 10
                                        }}
                                        title={<TuneOutlinedIcon fontSize='inherit'/>}
                                        onClick={() => setIsOpenFilter(!isOpenFilter)}
                                    />
                                    <TubeDialog
                                        content={(
                                            <>
                                                <div style={{marginBottom: 10}}>Select columns to display</div>
                                                {Object.keys(header).map((key) => {
                                                    if(header[key].label){
                                                        return (
                                                            <TubeButton
                                                                key={key}
                                                                title={header[key].label}
                                                                active={columnFilter[key]}
                                                                onClick={() => setColumnFilter({...columnFilter, [key]: !columnFilter[key]})}
                                                            />
                                                        );
                                                    }
                                                })}
                                            </>
                                        )}
                                        isOpen={isOpenFilter}
                                        width={"auto"}
                                        sx={{
                                            right: 5,
                                            maxWidth: 400
                                        }}
                                    />
                                </>
                            )}
                            func={() => setIsOpenFilter(false)}
                        />
                    </div>
                </div>
            </div>
            {bulkEditComponent ? (
                <div className='bulk-edit' style={{
                    display: isOpenBulkEdit ? "block" : "none"
                }}>
                    {bulkEditComponent(() => setIsOpenBulkEdit(false), getDataWrapper)}
                </div>
            ) : null}
            <Table sx={{ width: "100%" }} aria-label="simple table" className='data-table'>
                {!isLoading || autoRefresh ? (
                    <React.Fragment>
                        {view === "compressed" ? (
                            <>
                                {!disableHeader ? (
                                    <TableHead>
                                        <TableRow className='data-table-header'>
                                            {!disableSelect ? (
                                                <TableCell>
                                                    <TubeCheckbox
                                                        checked={selected.length > 0}
                                                        onChange={() => handleSelectAll()}
                                                    />    
                                                </TableCell>
                                            ) : null}
                                            {Object.keys(header).map((key) => {
                                                return columnFilter[key] ? (
                                                    <TableCell key={key} onClick={!header[key].noSort ? () => handleSort(key) : null}>
                                                        {header[key].label}
                                                        {key === sort.column && !header[key].noSort ? (<ArrowDropDownIcon sx={{position:"absolute", marginLeft: "-4px", transform: `rotate(${sort.order === "desc" ? 0 : 180}deg)`}} />) : null}
                                                    </TableCell>
                                                ) : null;
                                            })}
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                ) : null}
                                <TableBody>
                                    <TableRow className='white-space-row'>
                                        <TableCell />
                                    </TableRow>
                                    {fetchData.data.map((row, key) => (
                                        <>
                                            <TableRow
                                                key={key}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                className="data-table-row"
                                            >
                                                {!disableSelect ? (
                                                    <TableCell component="th" scope="row">
                                                        <TubeCheckbox
                                                            checked={selected ? selected.includes(row.id) : false}
                                                            onChange={() => handleSelect(row.id, row.title ? row.title : row.name)}
                                                        />    
                                                    </TableCell>
                                                ) : null}
                                                {Object.keys(row).map(key => {
                                                    return columnFilter[key] ? (
                                                        <>
                                                            {header[key].field !== "hidden" && (
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    key={key}
                                                                    className={`${key} ${header[key].conditionalClass && header[key].conditionalClass.content === row[key] ? header[key].conditionalClass.className : ''}`}
                                                                >
                                                                    {header[key] && header[key].field === "image" ? (
                                                                        <Avatar
                                                                            alt={row[header[key].alt]}
                                                                            src={`${appUrl}/${row[key]}`}
                                                                            sx={{
                                                                                margin: "5px"
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                    {header[key] && header[key].field === "color" ? (
                                                                        <div
                                                                            style={{
                                                                                width: 32,
                                                                                height: 32,
                                                                                backgroundColor: row[key],
                                                                                borderRadius: 32
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                    {header[key] && header[key].field === "list" ? (
                                                                        <>
                                                                            {row[key] && row[key].map((item, i)  => (
                                                                                <TubeChip 
                                                                                    key={i}
                                                                                    label={capitalizeEveryWord(item.name)}
                                                                                    sx={{
                                                                                        width: "auto",
                                                                                        float: "left",
                                                                                        textAlign: "center"
                                                                                    }}
                                                                                />
                                                                            ))}
                                                                        </>
                                                                    ) : null}
                                                                    {header[key] && header[key].field === "html" ? (
                                                                        <div dangerouslySetInnerHTML={{__html: row[key]}} onClick={header[key].onClick ? () => header[key].onClick(row) : null} />
                                                                    ) : null}
                                                                    {header[key] && header[key].field !== "image" && header[key].field !== "color" && header[key].field !== "html" && header[key].field !== "list" ? row[key] : null}
                                                                </TableCell>
                                                            )}
                                                        </>
                                                    ) : null;
                                                    }
                                                )}
                                                {(extraUtils || editComponent || deleteUrl) && (
                                                    <TableCell component="th" scope="row" key={key} align={"right"} width={80}>
                                                        {extraUtils && extraUtils.map(button => (
                                                            <>
                                                                {(!button.conditionProp || row[button.conditionProp] === 1) && (
                                                                    <IconButton aria-label={button.history} onClick={() => button.onClick(row)}>
                                                                        {getIcon(button.icon)}
                                                                    </IconButton>   
                                                                )} 
                                                            </>
                                                        ))}
                                                        {editComponent && (
                                                            <IconButton aria-label="edit" onClick={() => { handleEdit(row["id"]); editOpenFunc()}}>
                                                                <ModeOutlinedIcon sx={{
                                                                    fontSize: 20,
                                                                    color: "#004159"
                                                                }}/>
                                                            </IconButton>
                                                        )}
                                                        {((!protectedFirst || row["id"] > 1) && deleteUrl) && (
                                                            <IconButton aria-label="delete" onClick={() => handleDelete(row["id"])}>
                                                                <DeleteOutlineOutlinedIcon sx={{
                                                                    fontSize: 20,
                                                                    color: "#004159"
                                                                }}/>
                                                            </IconButton>
                                                        )}
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                            {header.extraRow ? (
                                                <>
                                                    {header.extraRow.content(row["id"])}
                                                </>
                                            ) : null}
                                        </>
                                    ))}
                                </TableBody>
                            </>
                        ) : (
                            <TableBody>
                                {fetchDataAll.data.map((row, key) => (
                                    <TableRow 
                                        key={key}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            ':hover div:not(.no-preview) video': {
                                                display: "block !important"
                                            },
                                        }}
                                        className="data-table-row large-view"
                                    >
                                        {largeView && largeView.map((column, j) => (
                                            <TableCell key={j} component="th" scope="row" >
                                                {column.map((field, i) => (
                                                    <Box key={i} className={`${field.type} ${row[field.id] === "no-preview" ? "no-preview" : ""}`} sx={{
                                                        backgroundImage: field.type === "thumbnail" ? `url(${appUrl}/${row[field.id]})` : "none",
                                                        paddingLeft: field.padding ? `${field.padding}px` : "0px" 
                                                    }}>
                                                        {field.type === "thumbnail" ? (
                                                            <>
                                                                <video autoPlay loop src={`${appUrl}/${row[field.id].replace("jpg", "mp4").replace("thumbs/", "thumbs/preview-")}`} width={150} height={85} style={{display:"none"}}/>
                                                                {row[field.id] !== "no-preview" ?
                                                                    <PlayArrowOutlinedIcon />
                                                                    :
                                                                    <div className="no-preview-text">No preview</div>
                                                                }
                                                            </>
                                                        ) : (field.type === "avatar" ? (
                                                            <>
                                                                <Avatar
                                                                    alt={row[field.id]}
                                                                    className="user-avatar"
                                                                    src={`${appUrl}/${field.avatarName ? row[field.avatarName] : row[`${field.id}Avatar`]}`}
                                                                    sx={{ width: field.size ? field.size : 34, height: field.size ? field.size : 34 }}
                                                                >
                                                                    {row[field.id] ? row[field.id].match(/\b(\w)/g).join('') : null}
                                                                </Avatar>
                                                                {field.online ? <div className='user-online'/> : null}
                                                            </>
                                                        ) : (field.type === "photoThumbnail" ? (
                                                            <div className='photo' style={{backgroundImage: `url(${appUrl}/${row[field.id]})`}} />
                                                        ) : (
                                                            <>
                                                                {field.icon} {row[field.id]}
                                                            </>
                                                        )))}
                                                    </Box>
                                                ))}
                                            </TableCell>
                                        ))}
                                        <TableCell component="th" scope="row" key={key} align={"right"} width={largeView ? "100%" : 80}>
                                            <IconButton aria-label="edit" onClick={() => {handleEdit(row["id"]); editOpenFunc()}}>
                                                <ModeOutlinedIcon sx={{
                                                    fontSize: 20,
                                                    color: "#004159"
                                                }}/>
                                            </IconButton>
                                            {(!protectedFirst || row["id"] > 1) && (
                                                <IconButton aria-label="delete" onClick={() => handleDelete(row["id"])}>
                                                    <DeleteOutlineOutlinedIcon sx={{
                                                        fontSize: 20,
                                                        color: "#004159"
                                                    }}/>
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </React.Fragment>
                ) : (
                    <tbody>
                        <tr>
                            <td style={{
                                width: "100%",
                                textAlign: "center",
                                padding: "50px 0"
                            }}>
                                <CircularProgress />
                            </td>
                        </tr>
                    </tbody>
                )}
            </Table>
            <DataTablePagination
                page={subpageUrl ? subpage : page ? page : 1}
                url={subpageUrl ? subpageUrl : url}
                totalPages={fetchData.last_page}
                showPerPage={showPerPage}
                setShowPerPage={setShowPerPage}
            />
            <Dialog
                open={openDelete}
                onClose={() => {setOpenDelete(false)}}
                content={isLoadingDelete ? "" : idDelete > 0 || idDelete.length > 0 ? "Are you sure you want to delete selected item/s?" : "You have not selected any files."}
                isLoading={isLoadingDelete}
                buttons={isLoadingDelete ? [] : idDelete > 0 || idDelete.length ? [
                    {
                        variant: "contained",
                        label: "Delete",
                        color: "error",
                        onClick: () => post(`${deleteUrl}`, { items: !idDelete ? selected : idDelete }, () => handleDeleteDone(setOpenDelete, setIsLoadingDelete), () => setIsLoadingDelete(true))
                    },
                    {
                        variant: "outlined",
                        label: "Cancel",
                        color: "primary",
                        onClick: () => setOpenDelete(false),
                    }
                ] : [
                    {
                        variant: "contained",
                        label: "OK",
                        color: "primary",
                        onClick: () => setOpenDelete(false),
                    }
                ]}
            />
            <Dialog
                open={openEdit}
                onClose={() => { setOpenEdit(false); closeFunc() }}
                content={editComponent && editComponent(idEdit, () => { setOpenEdit(false); getDataWrapper() })}
                maxWidth={editMaxWidth ? editMaxWidth : null}
                isLoading={editComponentIsLoading}
                buttons={
                    subTab === "videos" || subTab === "albums" ? [
                        {
                            variant: "outlined",
                            label: "Cancel",
                            color: "secondary",
                            onClick: () => { setOpenEdit(false); closeFunc() },
                        }
                    ] : [
                        {
                            variant: "outlined",
                            label: "Delete",
                            color: "secondary",
                            onClick: () => handleDelete(idEdit),
                        },
                        {
                            variant: "outlined",
                            label: "Cancel",
                            color: "secondary",
                            onClick: () => { setOpenEdit(false); closeFunc() },
                        }
                    ]
                }
                form={true}
            />
        </TableContainer>
    );
}

DataTable.propTypes = {
    data: PropTypes.array,
    deleteUrl: PropTypes.string,
    url: PropTypes.string,
    dataUrl: PropTypes.string,
    header: PropTypes.object,
    editComponent: PropTypes.func,
    bulkEditComponent: PropTypes.func,
    selectedStateName: PropTypes.string,
    dispatchFunc: PropTypes.func,
    largeView: PropTypes.array,
    filterStateName: PropTypes.string,
    actions: PropTypes.any,
    editMaxWidth: PropTypes.number,
    editComponentIsLoading: PropTypes.bool
};